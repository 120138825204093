@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

body {
	font-family: 'Source Sans Pro', sans-serif;
	margin-top:20px;
	background:#eee;
}

.app {
	padding: 0 2rem;
}

